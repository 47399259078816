<template>
  <div class="row">
    <div class="col-12 col-lg-7 c g">
      <div class="card">
        <div class="card-header text-center">
          <h4 class="text-center" v-if="edit">
            <i class="fa fa-edit"></i>
            تعديل خط السير
          </h4>
          <h4 class="text-center" v-if="!edit">
            <i class="fa fa-user-plus"></i>
            اضافة خط سير جديد
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for="">العنوان</label>
            <input type="text" class="form-control" v-model="group.title" />
          </div>
          <div class="form-group">
            <label for="">التفاصيل / الوصف</label>
            <textarea
              class="form-control"
              v-model="group.details"
              rows="3"
            ></textarea>
          </div>
          <div class="form-group">
            <h5 for="">القسم</h5>
            <select
              class="form-control"
              name=""
              v-model="group.section_id"
              id=""
            >
              <template v-for="section in sections">
                <option :value="section._id" :key="section._id">
                  {{ section.title }}
                </option>
              </template>
            </select>
          </div>
          <div class="g">
            <b-form-checkbox
              v-model="group.update_location"
              name="check-button"
              class="custom-control-success"
              inline
            >
              <h5>السماح بتحديث نقاط التوقف للطلاب من التطبيق</h5>
            </b-form-checkbox>
          </div>
          <div class="col-12 text-center g">
            <button class="btn btn-secondary" v-if="loading">
              <i class="fa fa-clock"></i> جاري التحميل...
            </button>
            <button class="btn btn-primary" v-if="!loading" @click="done()">
              <span v-if="!edit"> اضافة الآن </span>
              <span v-if="edit"> تعديل الآن </span>
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { BFormCheckbox } from "bootstrap-vue";
export default {
  components: {
    BFormCheckbox,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      loading: false,
      user: JSON.parse(localStorage.getItem("user")),
      group: {},
      edit: false,
      sections: [],
    };
  },
  created() {
    if (!checkPer("groups")) {
      this.$router.push("/per");
      return false;
    }
    var g = this;
    $.post(api + "/user/sections/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.sections = r.response;
      if (window.location.href.includes("#")) {
        g.edit = true;
        $.post(api + "/user/groups/group", {
          jwt: g.user.jwt,
          id: window.location.hash.split("#")[1],
        }).then(function (r) {
          g.group = r.response;
        });
      } else {
        if (g.sections.length) {
          g.group.section_id = g.sections[0]._id;
        }
        g.viewPoints = true;
      }
    });
  },
  methods: {
    checkPer(e) {
      return checkPer(e);
    },
    done() {
      var g = this;
      $.post(api + "/user/groups/add-or-edit", {
        jwt: this.user.jwt,
        edit: this.edit,
        group: this.group,
      })
        .then(function (e) {
          if (e.status != 100) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e.response,
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          } else {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم تعديل خط السير بنجاح",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            if (!g.edit) {
              g.group = {};
              if (g.sections.length) {
                g.group.section_id = g.sections[0]._id;
              }
            }
          }
        })
        .catch(function () {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ",
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>